<template>
  <NotFound v-if="isNotFound" :item="isNotFoundItem" />
  <template v-else>
    <AppHeader />
    <div id="App_Loading" v-loading.fullscreen="!initialized && !projectIdNotFound">
      <router-view v-if="projectIdNotFound || initialized" />
    </div>
    <AppDialog />
    <AppNotificationDialog />
  </template>
  <el-dialog
    center
    width="90%"
    top="0"
    :model-value="updateDialogVisible"
    :close-on-click-modal="false"
    :title="$t('info')"
    @closed="updateDialogVisible = false"
  >
    <div class="dialogMessage">
      {{ $t('confirm_update') }}
    </div>
    <template #footer>
      <div class="buttons">
        <div class="flex justify-evenly">
          <span class="dialog-footer">
            <el-button id="AppDialog_closeDialog" @click="remindUpdateLater()">
              {{ $t('later') }}
            </el-button>
          </span>
          <span class="dialog-footer">
            <el-button id="AppDialog_closeDialog" type="primary" @click="confirmUpdateVersion()">
              {{ $t('update') }}
            </el-button>
          </span>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import AppDialog from '@/components/AppDialog.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppNotificationDialog from '@/components/AppNotificationDialog.vue'
import NotFound from '@/components/common/NotFound.vue'
import {
  FETCH_PROFILE,
  FETCH_PROJECT,
  LOGOUT,
  OPEN_DIALOG,
  RESET_PAGE_NOT_FOUND,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import { addMinutes, isAfter } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import ReactGA from 'react-ga4'
import { deleteCookie, getCookie, setCookie } from 'smartbarcode-web-core/src/utils/helpers'
import { IProject, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { LOCAL_STORAGE_ITEM, SMARTBARCODE_TYPE } from './utils/constants'
import errorHandler from './utils/errorHandler'

@Options({
  components: {
    AppHeader,
    AppDialog,
    NotFound,
    AppNotificationDialog,
  },
  name: 'App',
})
export default class App extends Vue {
  updateDialogVisible = false
  remindUpdateDate = (undefined as unknown) as Date

  get isNotFound() {
    return this.$store.state.isNotFound
  }

  get isNotFoundItem() {
    return this.$store.state.notFoundItem
  }

  get showHeader() {
    return this.$route.meta.header
  }

  get user() {
    return this.$store.state.profile.user
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get project(): IProject {
    return this.$store.state.project.details
  }

  get initialized() {
    return this.$store.state.project.initialized
  }

  get projectIdNotFound() {
    return !this.$route.params.project
  }

  redirectHome() {
    this.$router.replace({
      name: 'home',
      params: {
        project: this.$route.params.project,
      },
    })
  }

  async mounted() {
    this.$store.dispatch(FETCH_PROFILE)
  }

  created() {
    document.addEventListener('onUpdateVersionNotification', (() => {
      if (this.remindUpdateDate) {
        if (isAfter(new Date(), this.remindUpdateDate)) {
          this.updateDialogVisible = true
        }
      } else {
        this.updateDialogVisible = true
      }
    }) as EventListener)
  }

  confirmUpdateVersion() {
    window.location.reload()
  }

  remindUpdateLater() {
    this.updateDialogVisible = false
    this.remindUpdateDate = addMinutes(new Date(), 15)
  }

  @Watch('initialized')
  onInitializedChange(initialized: boolean) {
    if (initialized && (!this.project || !this.$route.params.project)) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'project' })
    }
  }

  @Watch('isAuth')
  onAuthChange(isAuth: boolean) {
    const isRestrictedPage = this.$route.meta.restricted
    if (!isAuth && isRestrictedPage) {
      this.redirectHome()
    }
    if (this.isAuth) {
      const lastAuthorizedSystem = getCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM)
      if (!lastAuthorizedSystem) {
        setCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM, SMARTBARCODE_TYPE.MOBILE, 365)
      } else {
        if (getCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM) === SMARTBARCODE_TYPE.PORTAL) {
          this.$store.dispatch(LOGOUT)
          deleteCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM)
        }
      }
    } else {
      deleteCookie(LOCAL_STORAGE_ITEM.LAST_AUTHORIZED_SYSTEM)
    }
  }

  @Watch('$route.params')
  onRouterChange() {
    this.$store.dispatch(RESET_PAGE_NOT_FOUND)
  }

  @Watch('$route.params.project')
  onProjectIdChange(projectParam: string) {
    try {
      const changeInID = projectParam && projectParam !== this.$store.getters.projectParam
      if (projectParam !== 'undefined' && (!this.project || changeInID)) {
        this.$store.dispatch(FETCH_PROJECT, projectParam)
      }
    } catch (e) {
      errorHandler(e as TError)
    }
  }

  /** pages that don't need to check enterprise for display dialog */
  get exceptionPages() {
    return ['custom-html']
  }

  get isExceptionPage(): boolean {
    return this.exceptionPages.includes(this.$route.name)
  }

  @Watch('$route')
  @Watch('project')
  onRouteOrProjectChanged() {
    if (!this.project?.googleAnalyticsMeasurementId) return
    if (ReactGA.isInitialized) {
      ReactGA.reset()
    }

    ReactGA.initialize(
      (!isEmpty(this.project?.googleAnalyticsMeasurementId)
        ? this.project?.googleAnalyticsMeasurementId
        : process.env.VUE_APP_GA4_MEASUREMENT_ID) as string
    )
    ReactGA.send('pageview')
  }

  @Watch('project')
  onProjectChange(project: IProject) {
    if (project === null) return

    // Check if user belongs to the enterprise of project
    const profileEnterpriseId = this.$store.getters.enterpriseId
    if (this.isAuth && profileEnterpriseId !== project.enterpriseId && !this.isExceptionPage) {
      this.$store.dispatch(LOGOUT)
      this.$store.dispatch(OPEN_DIALOG, {
        message: this.$t('Not belong to Enterprise', {
          company: this.$store.state.project.enterprise,
        }),
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/mixins.scss';

#app {
  @-moz-document url-prefix() {
    button:disabled {
      pointer-events: none;
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: calc(100vh - 106px);
  background-color: $body-bg;
  font-size: $main-fsize;
}

a:-webkit-any-link {
  text-decoration: none;
}
</style>
